import "./experience.css";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

import { slideFromRight, workExperience } from "../../shared/constants";
import { Experience } from "../../shared/models/interfaces";
import AppWrap from "../../shared/components/wrapper/appWrap";
import TechStack from "../../shared/components/techStack";
import Achievements from "../../shared/components/achievements";
import CompanyLink from "../../shared/components/companyLink";

const WorkExperience = () => {
    const { ref, inView } = useInView({ threshold: 0.2 });

    return (
        <>
            <motion.div
                variants={slideFromRight}
                initial="whileOutView"
                animate={inView ? "whileInView" : "whileOutView"}
                className="experience-title"
                ref={ref}
            >
                <h1>Experience</h1>
            </motion.div>
            <div className="work-experience-container">
                <motion.div className="work-experience">
                    {workExperience.map(
                        (
                            {
                                name,
                                url,
                                company,
                                period,
                                achievements,
                                techStack,
                            }: Experience,
                            index
                        ) => {
                            return (
                                <div key={index}>
                                    <motion.div
                                        whileInView={{ opacity: [0, 1] }}
                                        transition={{ duration: 0.5 }}
                                        className="individual-work-experience"
                                        key={name}
                                    >
                                        <h4 className="bold-text title-name">
                                            {name}{" "}
                                        </h4>
                                        <CompanyLink
                                            url={url}
                                            company={`@${company}`}
                                        />

                                        <p>{period}</p>

                                        <Achievements
                                            achievements={achievements}
                                        />

                                        <TechStack technologies={techStack} />
                                    </motion.div>
                                </div>
                            );
                        }
                    )}
                </motion.div>
            </div>
        </>
    );
};

export default AppWrap(WorkExperience, "experience");
