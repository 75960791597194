import React from "react";

const Achievements: React.FC<{ achievements: string[] }> = ({
    achievements,
}) => {
    return (
        <ul>
            {achievements.map((achievement, index) => (
                <li key={index}>{achievement}</li>
            ))}
        </ul>
    );
};

export default Achievements;
