import "./skills.css";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { slideFromLeft, skills } from "../../shared/constants";
import { Skill } from "../../shared/models/interfaces";
import AppWrap from "../../shared/components/wrapper/appWrap";

const Skills = () => {
    const { ref, inView } = useInView({ threshold: 0.2 });

    return (
        <>
            <motion.div
                variants={slideFromLeft}
                initial="whileOutView"
                animate={inView ? "whileInView" : "whileOutView"}
                className="skills-title"
                ref={ref}
            >
                <h1>Technology Stack</h1>
            </motion.div>

            <div className="skills-container">
                <motion.div className="skill-list">
                    {skills.map(({ imageUrl, name }: Skill, index) => {
                        return (
                            <div className="skill app-flex" key={index}>
                                <div className="app-flex">
                                    <img src={imageUrl} alt={name} />
                                </div>
                                <p className="p-text">{name}</p>
                            </div>
                        );
                    })}
                </motion.div>
            </div>
        </>
    );
};

export default AppWrap(Skills, "skills");
