import React from "react";

const CompanyLink: React.FC<{ url: string; company: string }> = ({
    url,
    company,
}) => {
    return (
        <span className="p-text company">
            <a href={url} target="_blank" rel="noreferrer">
                {company}
            </a>
        </span>
    );
};

export default CompanyLink;
