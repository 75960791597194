import "./projects.css";
import { useState, useEffect } from "react";
import { AiFillGithub } from "react-icons/ai";
import { motion } from "framer-motion";
import AppWrap from "../../shared/components/wrapper/appWrap";
import { projectFilters, portfolioProjects } from "../../shared/constants";
import { PortfolioProject, AnimatedCard } from "../../shared/models/interfaces";
import { ProjectFilter } from "../../shared/models/projectFilter";
import TechStack from "../../shared/components/techStack";

const Projects = () => {
    const [filterProject, setfilterProject] =
        useState<PortfolioProject[]>(portfolioProjects);
    const [activeFilter, setActiveFilter] = useState<string>(ProjectFilter.All);
    const [animateCard, setAnimateCard] = useState<AnimatedCard>({
        y: 0,
        opacity: 1,
    });

    useEffect(() => {
        setAnimateCard({ y: 100, opacity: 0 });
        setTimeout(() => {
            setAnimateCard({ y: 0, opacity: 1 });
        }, 500);

        if (activeFilter === ProjectFilter.All) {
            setfilterProject(portfolioProjects);
        } else {
            let filteredDisplay: PortfolioProject[] = [];

            portfolioProjects.forEach((project) => {
                if (project.tags.includes(activeFilter)) {
                    filteredDisplay.push(project);
                }
            });
            setfilterProject(filteredDisplay);
        }
    }, [activeFilter]);

    const handleProjectFilter = (filter: string) => {
        setActiveFilter(filter);
    };

    return (
        <>
            <h2 className="head-text project-title">
                Some things I have built
            </h2>
            <div className="project-filter">
                {projectFilters.map((filter: string, index: number) => {
                    return (
                        <div
                            key={index}
                            onClick={() => handleProjectFilter(filter)}
                            className={`project-filter-item app-flex p-text ${
                                activeFilter === filter && "active-item"
                            }`}
                        >
                            {filter}
                        </div>
                    );
                })}
            </div>
            <motion.div
                animate={animateCard}
                transition={{ duration: 0.5, delayChildren: 0.5 }}
                className="project-portfolio"
            >
                {filterProject.map(
                    (
                        {
                            repositoryUrl,
                            imageUrl,
                            title,
                            description,
                            techStack,
                            tags,
                        }: PortfolioProject,
                        index
                    ) => {
                        return (
                            <div key={index} className="project-item app-flex">
                                <a
                                    href={repositoryUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                    className={
                                        title === "Portfolio"
                                            ? "deactivated"
                                            : ""
                                    }
                                >
                                    <div className="project-image app-flex">
                                        <img src={imageUrl} alt={title} />
                                        <motion.div
                                            whileHover={{ opacity: [0, 1] }}
                                            initial={{ opacity: 0 }}
                                            transition={{
                                                duration: 0.25,
                                                ease: "easeInOut",
                                                staggerChildren: 0.5,
                                            }}
                                            className="project-hover app-flex"
                                        >
                                            <motion.div
                                                whileInView={{ scale: [0, 1] }}
                                                whileHover={{ scale: [1, 0.9] }}
                                                transition={{
                                                    duration: 0.25,
                                                }}
                                                className="app-flex"
                                            >
                                                <AiFillGithub />
                                            </motion.div>
                                        </motion.div>
                                    </div>
                                </a>
                                <div className="project-content app-flex">
                                    <h4 className="bold-text head-text">
                                        {title}
                                    </h4>
                                    <p className="p-text">{description}</p>

                                    <TechStack technologies={techStack} />

                                    <div className="project-tag app-flex">
                                        <p className="p-text"> {tags[0]}</p>
                                    </div>
                                </div>
                            </div>
                        );
                    }
                )}
            </motion.div>
        </>
    );
};

export default AppWrap(Projects, "projects");
