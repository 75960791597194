import "./about.css";
import { motion } from "framer-motion";
import { AboutSection } from "../../shared/models/interfaces";
import { aboutSections } from "../../shared/constants";
import AppWrap from "../../shared/components/wrapper/appWrap";
import CompanyLink from "../../shared/components/companyLink";

const AboutMe = () => {
    return (
        <>
            <div className="about-bio">
                <p className="about-welcome p-text">
                    Welcome, nice to have you here!
                </p>
                <h2 className="head-text">
                    {" "}
                    I am keen on learning new technologies, logic and systems'
                    patterns, problem solving and combining aesthetics with
                    functionality.{" "}
                </h2>
                <p className="p-text">
                    Currently, I am focused on building customer tailored
                    products at{" "}
                    <CompanyLink
                        url="https://www.maibornwolff.de/"
                        company="MaibornWolff"
                    />
                    .
                </p>
                <p className="p-text">
                    Besides tackling complex problem-solving challenges, I enjoy
                    ensuring software quality through practices like clean
                    coding, thorough code reviews and collaborative pair
                    programming sessions. I believe sharing knowledge is
                    essential in Agile software development teams.{" "}
                </p>
                <p className="p-text">
                    Before pursuing a career in software engineering, I had the
                    opportunity to explore my passion for design, fine arts and
                    entrepreneurship. Through these experiences, I honed my
                    creativity, attention to detail and communication skills -
                    all of which have proven invaluable in my work as a software
                    engineer. Outside of coding, I love to unwind by playing
                    chess and tending to my garden.{" "}
                </p>
            </div>
            <div className="app-profiles">
                {aboutSections.map(
                    ({ imageUrl, title, description }: AboutSection, index) => {
                        return (
                            <motion.div
                                whileInView={{ opacity: 1 }}
                                whileHover={{ scale: 1.1 }}
                                transition={{
                                    duration: 0.5,
                                    type: "tween",
                                }}
                                className="profile-item"
                                key={index}
                            >
                                <img src={imageUrl} alt={title} />
                                <h2 className="bold-text about-titles">
                                    {title}
                                </h2>
                                <p className="p-text">{description}</p>
                            </motion.div>
                        );
                    }
                )}
            </div>
        </>
    );
};

export default AppWrap(AboutMe, "about");
