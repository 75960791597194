import { Variants } from "framer-motion";
import {
    AboutSection,
    PortfolioProject,
    Skill,
    Experience,
} from "./models/interfaces";
import { ProjectFilter } from "./models/projectFilter";
import { SectionIds } from "./models/sectionIds";
import { Technology } from "./models/technology";

const {
    Angular,
    AWS,
    Bash,
    Cucumber,
    CSharp,
    Express,
    Figma,
    Git,
    GraphQL,
    Handlebars,
    Heroku,
    Java,
    JavaScript,
    Jest,
    JUnit,
    Node,
    PostgreSQL,
    Python,
    Quarkus,
    React,
    Redux,
    Remix,
    RxJS,
    SocketIO,
    SpringBoot,
    Tableau,
    TypeScript,
    Vue,
} = Technology;

const {
    Home,
    About,
    Experience: ExperienceSection,
    Skills,
    Projects,
    Contact,
} = SectionIds;

const { SinglePageApplication, MultiPageApplication, Game, All } =
    ProjectFilter;

export const aboutSections: AboutSection[] = [
    {
        title: "Full Stack Web Development",
        description:
            "I build things for the web by developing both the front end (client side) and the back end (server side) portions of an application.",
        imageUrl: "/full-stack.jpg",
    },
    {
        title: "Backend Development",
        description:
            "By focusing on databases, back-end logic, application programming interface (APIs), architecture and servers, I ensure web applications perform correctly.",
        imageUrl: "/back-end.jpg",
    },
    {
        title: "Frontend Development",
        description:
            "I use JavaScript / TypeScript and their frameworks to build interactive web interfaces. I strive to provide users with the best experience possible centered around sound design principles. ",
        imageUrl: "/front-end.jpg",
    },
];

export const applicationSections: string[] = [
    Home,
    About,
    ExperienceSection,
    Skills,
    Projects,
    Contact,
];

export const slideFromLeft: Variants = {
    whileInView: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 0.75,
            ease: "easeInOut",
        },
    },
    whileOutView: {
        x: "-100%",
        opacity: 0,
    },
};

export const slideFromRight: Variants = {
    whileInView: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 0.6,
            ease: "easeInOut",
        },
    },
    whileOutView: {
        x: "50%",
        opacity: 0,
    },
};

export const skills: Skill[] = [
    {
        name: TypeScript,
        imageUrl:
            "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/typescript/typescript-original.svg",
    },
    {
        name: React,
        imageUrl:
            "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg",
    },
    {
        name: Angular,
        imageUrl:
            "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/angularjs/angularjs-original.svg",
    },
    {
        name: RxJS,
        imageUrl: "https://rxjs.dev/assets/images/favicons/favicon-192x192.png",
    },
    {
        name: Vue,
        imageUrl:
            "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/vuejs/vuejs-original.svg",
    },
    {
        name: Remix,
        imageUrl: "https://avatars.githubusercontent.com/u/64235328?s=200&v=4",
    },
    {
        name: GraphQL,
        imageUrl:
            "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/graphql/graphql-plain.svg",
    },
    {
        name: Java,
        imageUrl:
            "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/java/java-original.svg",
    },
    {
        name: SpringBoot,
        imageUrl:
            "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/spring/spring-original.svg",
    },
    {
        name: Quarkus,
        imageUrl:
            "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/quarkus/quarkus-original.svg",
    },
    {
        name: Node,
        imageUrl:
            "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original.svg",
    },
    {
        name: PostgreSQL,
        imageUrl:
            "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/postgresql/postgresql-original.svg",
    },
    {
        name: Jest,
        imageUrl:
            "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/jest/jest-plain.svg",
    },
    {
        name: JUnit,
        imageUrl:
            "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/junit/junit-original.svg",
    },
    {
        name: Cucumber,
        imageUrl:
            "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/cucumber/cucumber-plain.svg",
    },
    {
        name: AWS,
        imageUrl:
            "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/amazonwebservices/amazonwebservices-original-wordmark.svg",
    },
    {
        name: Bash,
        imageUrl:
            "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/bash/bash-original.svg",
    },
    {
        name: Git,
        imageUrl:
            "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-original.svg",
    },
];

export const workExperience: Experience[] = [
    {
        name: "Software Engineer",
        company: "MaibornWolff GmbH",
        achievements: [
            "Full-stack engineer (backend focus) for a damage inspection and assessment web application. Developed business features with TypeScript, React, Java and Quarkus. Implemented automated testing with Jest and JUnit.",
            "Full-stack engineer for an application that extends assistive robot aid for individuals with disabilities with a web interface used for monitoring and management. Built application components with TypeScript, React, Remix, Prisma and PostgreSQL. Implemented automated and behaviour driven testing using Jest and Cucumber. Engineered custom design system with Storybook.",
            "Full-stack engineer for an electronic components configurator application. Implemented business features using TypeScript, Angular, C#, .NET and automated testing with Jest and xUnit.",
            "Frontend engineer for a shared e-mobility application comissioned by a leading German car manufacturer. Built and optimized components with Typescript and React.",
            "Frontend engineer for a web application used to manage and gather data insights from different projects in the IT sector. Designed and built features using TypeScript, Vue.js, Vuetify and GraphQL.",
            "Collaborated in an Agile environment, communicating regularly with cross-functional teams and clients.",
        ],
        period: "November 2022 - present",
        url: "https://www.maibornwolff.de/",
        techStack: [
            TypeScript,
            CSharp,
            Java,
            Quarkus,
            Angular,
            RxJS,
            Vue,
            React,
            Remix,
            PostgreSQL,
            GraphQL,
            Jest,
            JUnit,
            Git,
            Bash,
            Cucumber,
        ],
    },
    {
        name: "Full Stack Developer",
        company: "Spiced Academy",
        achievements: [
            "Built four full-stack web applications, each with unique solutions and user experiences.",
            "Developed proficiency in integrating frontend and backend technologies using a variety of frameworks such as React, Vue and Node, with a deep understanding of core concepts in JavaScript and TypeScript. Worked with databases such as PostgreSQL to store and manage data.",
            "Successfully completed a rigorous 12-week, full-time program, gaining comprehensive knowledge and skills in full-stack development.",
        ],
        period: "May 2022 - September 2022",
        url: "https://www.spiced-academy.com/en",
        techStack: [
            JavaScript,
            TypeScript,
            Vue,
            React,
            Redux,
            SocketIO,
            Jest,
            Node,
            Express,
            PostgreSQL,
            AWS,
            Git,
        ],
    },
    {
        name: "Digital Jobs Compass",
        company: "StartSteps",
        achievements: [
            "Introduction to data analytics, web development, UX/UI design, cybersecurity, digital marketing, project management and agile methodologies. Participated in hands-on projects across these domains.",
            "Foundational technical skills in Python and JavaScript, along with collaboration and problem-solving abilities.",
        ],
        period: "February 2022 - March 2022",
        url: "https://startsteps.org/en",
        techStack: [Python, JavaScript, Tableau, Figma, Git],
    },
];

export const projectFilters: string[] = [
    SinglePageApplication,
    MultiPageApplication,
    Game,
    React,
    Vue,
    All,
];

export const portfolioProjects: PortfolioProject[] = [
    {
        title: "Circe",
        description:
            "A single page application that allows users to trade plants with each other.",
        repositoryUrl: "https://github.com/JoaoTeixeira13/circe",
        imageUrl: "/works/circe.gif",
        techStack: [JavaScript, AWS, Node, Express, PostgreSQL, React, Redux],
        tags: [SinglePageApplication, React, All],
    },
    {
        title: "Social Network",
        description:
            "A single page application that allows users to join a social network.",
        repositoryUrl: "https://github.com/JoaoTeixeira13/social-network",
        imageUrl: "/works/socialNetwork.gif",
        techStack: [
            JavaScript,
            AWS,
            Node,
            Express,
            PostgreSQL,
            SocketIO,
            React,
            Redux,
        ],
        tags: [SinglePageApplication, React, All],
    },
    {
        title: "Image Board",
        description: "An Instagram-like, single page image board application.",
        repositoryUrl: "https://github.com/JoaoTeixeira13/image-board",
        imageUrl: "/works/imageBoard.gif",
        techStack: [JavaScript, Vue, Node, Express, PostgreSQL, AWS],
        tags: [SinglePageApplication, Vue, All],
    },
    {
        title: "Petition",
        description:
            "A multi page application that allows users to register and sign a petition.",
        repositoryUrl: "https://github.com/JoaoTeixeira13/petition",
        imageUrl: "/works/petition.gif",
        techStack: [JavaScript, Handlebars, Node, Express, PostgreSQL, Heroku],
        tags: [MultiPageApplication, All],
    },
    {
        title: "Connect Four",
        description:
            "A two-player game where the first user to connect four pieces wins.",
        repositoryUrl: "https://github.com/JoaoTeixeira13/connect-four",
        imageUrl: "/works/connectFour.gif",
        techStack: [JavaScript],
        tags: [Game, All],
    },
    {
        title: "Portfolio",
        description:
            "The page you are currently viewing. A portfolio to showcase some of my personal projects.",
        repositoryUrl: "https://github.com/JoaoTeixeira13/portfolio",
        imageUrl: "/works/portfolio.gif",
        techStack: [TypeScript, React],
        tags: [SinglePageApplication, React, All],
    },
];
