import React from "react";
import { Technology } from "../models/technology";

const TechStack: React.FC<{ technologies: Technology[] }> = ({
    technologies,
}) => {
    return (
        <div className="tech-stack">
            {technologies.sort().map((technology) => (
                <span key={technology} className="technology-badge">
                    {technology}
                </span>
            ))}
        </div>
    );
};

export default TechStack;
