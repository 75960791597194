import { Link } from "react-scroll";
import { ActiveProp } from "../models/interfaces";
import { applicationSections } from "../constants";

export default function NavigationDots({ active }: ActiveProp) {
    return (
        <div className="navigation">
            {applicationSections.map((section: string, index: number) => (
                <Link
                    activeClass="active"
                    className="navigation-dot"
                    to={section}
                    spy={true}
                    smooth={true}
                    offset={-80}
                    duration={500}
                    key={index}
                    style={
                        active === section
                            ? { backgroundColor: `var(--green)` }
                            : {}
                    }
                />
            ))}
        </div>
    );
}
